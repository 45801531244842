import React from 'react'
import { ColumnFilter } from '../ColumnFilter'



export const COLUMNS = [
  // {
  //   Header: 'Banque Pro',
  //   accessor: 'Banque Pro',
  //   Filter: ColumnFilter,
  //   sticky: 'left',
  //   width: 170,
  //   // width: 50,
  //   Cell: e =>{
  //     let val = e.value.split(',')
      
  //     return (

  //      <div style={{marginTop: -5}}>
  //        <a href={`/go/?go=${val[0]}&url=${val[2]}`}
  //        state={{url: val[1]}}
  //        target='_blank' rel="noreferrer">
  //        <img style={{width: 150}} src={`/logos/${val[0].replace(' ', '').replace('.', '').toLowerCase()}.png`} alt={`${e.value}`}></img>
  //      </a>

  //        </div>
  //     )
  //   },
  // },
 
  {
    Header: 'Carte bancaire',
    accessor: 'Banque Pro Carte',
    Filter: ColumnFilter,
    sticky: 'left',
    width: 180,
    mobile: 'yes',
    // width: 50,
    Cell: e =>{
      let val = e.value.split(',')
      
      return (

       <div style={{marginTop: -5}}>
         <a href={`/go/?go=${val[0]}`}
         target='_blank' rel="noreferrer">
         <img style={{width: 150}} src={`/cartes/${val[0].replace(' ', '-').toLowerCase()}-${val[1].replace(' ', '-').replace(' ', '-').replace('é', 'e').replace('é', 'e').toLowerCase()}.jpg`} alt={`${e.value}`}></img>
       </a>

         </div>
      )
    },
  },
 
  




  {
    Header: 'Tarifs',
    accessor: 'Tarifs',
    Filter: ColumnFilter,
    width: 190,
    padding: 27,
    mobile: 'yes',
    Cell : e => {
      
      if(e.value === 'illimité'){
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
            <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
          </div> 
        )
      } else {
        return (
          <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
          <div style={{fontSize: 24}}>{e.value}€</div>
          <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>par mois</div>
        </div>
        )
      }
  }},

//   {
  
//     Header: () => (<div style={{float: 'left', marginLeft:29, marginRight: -9, marginTop: -10}}><div>Nombre</div><div style={{marginTop: -5}}>d'utilisateurs</div></div>),
//     accessor: "Nombre d'utilisateurs",
//     Filter: ColumnFilter,
//     width: 130,
//     padding: 27,
//     Cell : e => {
  
//       if(e.value === 'illimité'){
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//             <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
//           </div> 
//         )
//       } else if (e.value === 1) {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//           <div style={{fontSize: 24}}>{e.value}</div>
//           <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateur</div>
//         </div>
//         )
//       }
//       else {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//           <div style={{fontSize: 24}}>{e.value}</div>
//           <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>utilisateurs</div>
//         </div>
//         )
//       }

//   }},

//   {
//     Header: "Virements € inclus",
//     accessor: "Virements € inclus",
//     Filter: ColumnFilter,
//     width: 150,
//     padding: 27,
//     Cell : e => {
  
//       if(e.value === 'illimité'){
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//             <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
//           </div> 
//         )
//       } else {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//           <div style={{fontSize: 24}}>{e.value}</div>
//           <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>par mois</div>
//         </div>
//         )
//       }
//   }},

//   {
//     Header: "Retrait € distributeur",
//     accessor: "Retrait € distributeur",
//     Filter: ColumnFilter,
//     width: 150,
//     padding: 27,
//     Cell : e => {
//       if(e.value === 'illimité'){
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//             <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
//           </div> 
//         )
//       } else {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//           <div style={{fontSize: 24}}>{e.value.split(',')[0]}</div>
//           <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div>
//         </div>
//         )
//       }
//   }},


//   {
//     Header: "Retrait hors zone euro",
//     accessor: "Retrait hors zone euro",
//     Filter: ColumnFilter,
//     width: 150,
//     padding: 27,
//     Cell : e => {
//       if(e.value === 'illimité'){
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//             <div style={{fontSize: 20, padding: 8}}>{e.value}</div>
//           </div> 
//         )
//       } else {
//         return (
//           <div style={{fontFamily: '"Helvetica", Sans-serif'}}>
//           <div style={{fontSize: 24}}>{e.value.split(',')[0]}</div>
//           <div style={{fontWeight: 300, fontSize: 14, marginTop: -4}}>{e.value.split(',')[1]}</div>
//         </div>
//         )
//       }
//   }},





  
//   {
//     Header: 'En savoir +',
//     accessor: 'Banque Pro Website',
//     width: 170,
//     padding: 18,
//     Cell: e =>{
//       let val = e.value.split(',')

//       return (
//         // <span>{e.value}</span>
//         <div style={{padding: 6}}>

// <a href={`/go/?go=${val[0]}&url=${val[2]}`}
//          state={{url: val[1]}}
//          target='_blank' rel="noreferrer">
//                 <BS.Button id="comparateurButton" type="submit" className="btn-lg" style={{height: 40, backgroundColor: '#E5234F', borderColor: '#E5234F', width: 120, padding: 7, fontSize: 14, boxShadow: '0 0 8px 0 rgb(0 0 0 / 30%)'}}>See offer <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5}}/></BS.Button>
//           </a>
//         </div>
//       )
//     },
//   },
]