import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {
	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();



    return (
         <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Banque pour Association - Meilleure banque pro pour votre association</title>
                <meta name="description" content="Vous avez une association? Trouvez la meilleure banque pro avec bankbank." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque pro pour association</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque pro pour association</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro pour les associations</span> pour déterminer vos priorités : utilisateurs, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, width: '100%', textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Associations </Tab>
      {/* <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 employé.e.s</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 employé.e.s</Tab>
      <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Plus de 50 employé.e.s</Tab> */}
    </TabList>

    <TabPanel>
      <DataTable type='Société' row={[2,3,9,5,6,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[2,3,9,5,6,40]} id='mobileTable'/>
    </TabPanel>
      {/* <TabPanel>      
      <DataTable type='Société' row={[12,4,18,9,19,30,20]} id='wideTable'/>
      <DataTableMobile type='Société' id={[12,4,18,9,19,30,20]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>    
      <DataTable type='Société' row={[9,21,4,22,23,31]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,4,22,23,31]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38]} id='mobileTable'/>
    </TabPanel> */}

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
          
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
			
			<Post>
			<h2>Comment choisir la meilleure banque pour une association ?</h2>
<p>Il y’a 1.5M associations en France. Tous les ans, 70 000 nouvelles associations sont crées mais malheureusement 34 000 associations disparaissent.
</p>
<StaticImage
		  	src='../../images/banque-pro-association.jpeg'
			width={1200}
			alt='Banque pro pour association'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<p>
			Les associations sont réparties en 4 grandes catégories qui représentent 96% du secteur :
			</p>
<ul>
	<li>Sport</li>
	<li>Culture, spectacles et activités artistiques</li>
	<li>Loisir, divertissement et vie sociale</li>
	<li>Action sociale, humanitaire et et caritative / défense de caus</li>
</ul>


<h3>Quels sont les besoins bancaires de votre association ?</h3>
<p>
La grande majorité des associations ne sont pas employeuses, cependant les 159 000 qui emploient représentent 1.8M de salariés. Soit près de 10% du secteur privé. Et ces effectifs salariés augmentent de 0.5% par an grâce à un intérêt fort des seniors.
</p><p>
Le salaire moyen d’un employé d’une association est de 21 300 euros.
</p><p>
Si la masse salariale des associations est en croissance, c’est surtout grâce aux 11 millions de bénévoles, que ces activités arrivent réellement à aider les différentes communautés. Et gérer ces 11 millions d’individus n’est pas simple.
</p><p>
Chaque association a une activité différente mais une partie de la gestion qui ne change pas, c’est la banque. Une association a besoin d’une banque.
</p><p>
Pour clarifier, ce n’est pas une obligation légale pour une association d’ouvrir un compte bancaire mais c’est l’élément clé qui permettra de recevoir des dons, des adhésions et cotisations et de payer les différents salaires et prestataires.
</p>

<h3>
Banque traditionnelle ou néobanque – quelle banque pour votre association ?</h3>
<p>
Malheureusement, les banques en ligne pour particulier n’acceptent pas d’ouvrir de comptes pro pour les associations. Vous avez donc 2 options :
</p>
<ul>
	<li>Banques traditionnelles</li>
	<li>Néobanques Pro</li>
</ul>


				

			</Post>
		</Container>

		<Footer/>
      </PageWrapper>

    )
}

export default startabusiness
